import React, { type FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { isNil } from 'lodash'
import { Flex } from '../../Flex'
import { useQuestionKeyboardShortcuts } from './useQuestionKeyboardShortcuts'
import { DescriptionQuestionEditor } from '../DescriptionQuestionEditor'
import { QuestionIcon } from '../QuestionIcon'
import {
  type Question,
  QuestionType,
  UserQuestionType,
  DataPointValueType,
} from '../types'

import { QuestionTitleEditor } from '../QuestionTitleEditor'
import { useStyles } from './useStyles'
import { useFormBuilderContext } from '../useFormBuilderContext'
import { MultipleChoiceEditor } from '../../MultipleChoiceEditor'
import { ArrowUp, ArrowDown } from '../../Icons'
import { IconWrapper } from '../../IconWrapper'
import { IconButton } from '../../Button'
import { type Option } from '../../MultipleChoiceEditor/types'

interface QuestionEditorProps {
  question: Question
  onChange: (input: {
    title: string
    titleHtml?: string
    options?: Array<Option>
  }) => void
  onCopyQuestionOptions: (options: string) => void
  uploadWidgetConfig: {
    cloudName: string
    uploadPreset: string
  }
  onToggleMandatory: (isMandatory: boolean) => void
  questions: Array<Question>
}

export const QuestionEditor: FC<QuestionEditorProps> = ({
  question,
  onChange,
  uploadWidgetConfig,
  onCopyQuestionOptions,
  onToggleMandatory,
  questions,
}) => {
  const { t } = useTranslation()
  const {
    labels,
    onDeleteQuestion,
    getQuestionNumber,
    onDuplicateQuestion,
    setActiveQuestion,
  } = useFormBuilderContext()
  const { handleQuestionKeyboardShortcuts } = useQuestionKeyboardShortcuts({
    question,
    questions,
    onDeleteQuestion,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- async function
    onDuplicateQuestion,
    onCopyQuestionOptions,
    onToggleMandatory,
    setActiveQuestion,
  })
  const classes = useStyles()
  const [title, setTitle] = useState<string>(question.title ?? '')
  const [options, setOptions] = useState<Array<Option>>(question.options ?? [])
  const currentIndex = questions.findIndex(({ id }) => question.id === id)

  useEffect(() => {
    // Description questions are handled separately
    if (question.userQuestionType === UserQuestionType.Description) {
      return
    }
    if (
      title !== question.title ||
      options.length !== (question.options ?? []).length ||
      options.some(({ id, label, value }) => {
        const questionOption = (question.options ?? []).find(
          option => option.id === id,
        )
        return (
          isNil(questionOption) ||
          questionOption.label !== label ||
          questionOption.value !== value
        )
      })
    ) {
      onChange({ title, options })
    }
  }, [title, options])

  const handleDescriptionQuestionUpdate = (
    title: string,
    titleHtml: string,
  ): void => {
    if (title !== question.title) {
      setTitle(title)
      onChange({ title, titleHtml })
    }
  }

  const isMandatory = question.questionConfig?.mandatory === true

  const isMultipleChoice = question.questionType === QuestionType.MultipleChoice
  const isYesNo = question.userQuestionType === UserQuestionType.YesNo
  const isDescriptionQuestion =
    question.userQuestionType === UserQuestionType.Description

  const showSimpleTitle = !isDescriptionQuestion

  const handleSelectPreviousQuestion = (): void => {
    const prevIndex = currentIndex - 1
    setActiveQuestion(questions[prevIndex])
  }
  const handleSelectNextQuestion = (): void => {
    const nextIndex = currentIndex + 1
    setActiveQuestion(questions[nextIndex])
  }

  return (
    <Flex align='center' justify='center' direction='column'>
      <div className={classes.navigationButtonContainer}>
        <IconButton
          tooltip={t('form:builder.select_prev_question')}
          className={classes.navigationButton}
          onClick={handleSelectPreviousQuestion}
          disabled={currentIndex === 0}
        >
          <IconWrapper>
            <ArrowUp />
          </IconWrapper>
        </IconButton>
      </div>

      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        role='button'
        className={classnames(classes.question, classes.questionActive)}
        onKeyDown={event => {
          handleQuestionKeyboardShortcuts({
            event,
            isMultipleChoice,
            options: question.options,
          })
        }}
      >
        <div className={classes.questionTitle}>
          <div
            className={classnames(
              classes.iconWithNumber,
              classes.activeIconWithNumber,
            )}
          >
            {!isNil(question.userQuestionType) && (
              <QuestionIcon
                active
                userQuestionType={question.userQuestionType}
              />
            )}
            <div className={classes.questionNumber}>
              {getQuestionNumber(question.id)}
            </div>
          </div>
          {showSimpleTitle && (
            <QuestionTitleEditor
              title={title}
              onChange={setTitle}
              autoFocus={false}
              mandatory={isMandatory}
            />
          )}
        </div>
        {isMultipleChoice && (
          <MultipleChoiceEditor
            multipleChoice={{
              id: question.id ?? 'new_question',
              options: question.options ?? [],
              title: question.title ?? '',
              mandatory: isMandatory,
              dataPointValueType:
                question?.dataPointValueType ?? DataPointValueType.Number,
            }}
            onCopyQuestionOptions={onCopyQuestionOptions}
            onChangeOptions={setOptions}
          />
        )}

        {isDescriptionQuestion && (
          <DescriptionQuestionEditor
            question={question}
            onChange={handleDescriptionQuestionUpdate}
            uploadWidgetConfig={uploadWidgetConfig}
          />
        )}
        {isYesNo && (
          <div className={classes.yesNo}>
            <p className={classes.yesNoOption}>{labels.yes}</p>
            <p className={classes.yesNoOption}>{labels.no}</p>
          </div>
        )}
      </div>
      <div className={classes.navigationButtonContainer}>
        <IconButton
          disabled={currentIndex === questions.length - 1}
          tooltip={t('form:builder.select_next_question')}
          className={classes.navigationButton}
          onClick={handleSelectNextQuestion}
        >
          <IconWrapper>
            <ArrowDown />
          </IconWrapper>
        </IconButton>
      </div>
    </Flex>
  )
}
QuestionEditor.displayName = 'QuestionEditor'
