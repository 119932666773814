import { navigate } from '@reach/router'
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b'
import { isEmpty, isNil } from 'lodash'
import React, { useEffect, type FC, useState, type ReactNode } from 'react'
import { environment } from '../environment'
import { useCustify } from './useCustify'
import { useHeap } from './useHeap'
import { useCookies } from 'react-cookie'

interface AuthenticationGuardProps {
  children: ReactNode
  loadingComponent: ReactNode
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({
  children,
  loadingComponent,
}) => {
  const stytch = useStytchB2BClient()
  const [isLoading, setLoading] = useState(true)
  const [cookies] = useCookies(['stytch_session'])
  const { session } = useStytchMemberSession()

  // @TODO Check why we use hooks for tools inside the scope of Auth hook
  useHeap()
  useCustify()

  const redirectToLogin = (): void => {
    const urlParams = new URLSearchParams(window.location.search)
    const org = urlParams.get('org')

    const visitedPage = window.location.href
    const expires = new Date()
    expires.setTime(expires.getTime() + 10 * 60 * 1000)
    document.cookie = `auth_next_url=${visitedPage};expires=${expires.toUTCString()};path=/;domain=${
      environment.auth.auth_cookies_allowed_domain
    };`

    if (!isEmpty(org)) {
      void navigate(`${environment.urls.careops}/login/${org}`)
    } else {
      void navigate(`${environment.urls.careops}/login`)
    }
  }

  // we want to to redirect user to login if they don't have a cookie
  useEffect(() => {
    if (isNil(cookies?.stytch_session)) {
      redirectToLogin()
    }

    const authenticate = (): void => {
      if (!isNil(stytch.session.getSync())) {
        void stytch.session.authenticate({
          session_duration_minutes: 60,
        })
      }
    }

    // re-authenticate user every 50 minutes
    const interval = setInterval(authenticate, 3000000) // 50 minutes

    return () => {
      clearInterval(interval)
    }
  }, [stytch])

  // We want to prevent rendering conent before session for user is active
  useEffect(() => {
    if (!isNil(session)) {
      setLoading(false)
    }
  }, [session])

  if (isLoading) {
    return <>{loadingComponent}</>
  }

  return <>{children}</>
}

AuthenticationGuard.displayName = 'AuthenticationGuard'
