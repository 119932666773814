import { isEmpty, isNil } from 'lodash'
import React, { useEffect, useState, type FC } from 'react'
import { useDynamicConfig, useFeatureGate } from '@statsig/react-bindings'
import { parseISO, startOfDay, endOfDay } from 'date-fns'

import {
  DynamicConfigName,
  FeatureGateName,
  type DynamicConfigTypes,
} from '@awell/libs-web/src/experiments'

import { type SelectValue } from '@awell-health/design-system'

import { Chat } from '@awell/ui-kit/components/Chat'
import { ActivityFeed } from '@awell/ui-kit/compositions/ActivityFeed'
import { Feed } from './components/Feed'

import { ActivityAction, type Activity } from '../../hooks/useActivityFeed'
import { useBaselineInfo } from '../../hooks/useBaselineInfo'
import { useOrchestrationFactsPrompt } from '../../hooks/useOrchestrationFactsPrompt'
import { useScheduledSteps } from '../../hooks/useScheduledSteps'
import { useScheduledTracks } from '../../hooks/useScheduledTracks'
import { usePathwayContextProvider } from '../PathwayContextProvider'
import { useCareflowActivities } from '../../hooks/useCareflowActivities'

import { PathwayActivityFeedSkeleton } from './skeleton/PathwayActivityFeedSkeleton'
import { systemActivities, type ActivitiesListFilters } from './types'
import { CareflowActivityFilters } from './components/CareflowActivityFilters'

const updateActivityInList = (
  activity: Activity | null,
  prevActivities: Array<Activity>,
): Array<Activity> => {
  if (isNil(activity) || systemActivities.includes(activity.object.type)) {
    return prevActivities
  }

  const index = prevActivities.findIndex(a => a.id === activity.id)
  if (index === -1) return prevActivities

  const newActivities = [...prevActivities]
  newActivities[index] = activity
  return newActivities
}

export interface Props {
  pathwayId: string
  pathwayDefinitionId: string
}

export const PathwayActivityFeed: FC<Props> = ({
  pathwayId,
  pathwayDefinitionId,
}) => {
  const { value: showChat } = useFeatureGate(FeatureGateName.AUDIT_LOGS_AVA)
  const config = useDynamicConfig(DynamicConfigName.AUDIT_LOGS_AVA)

  const PAGE_SIZE = 100
  const [index, setIndex] = useState<number>(0)
  const [allActivities, setAllActivities] = useState<Array<Activity>>([])

  const { baselineDataPoints } = useBaselineInfo(pathwayId)
  const { scheduledSteps } = useScheduledSteps(pathwayId)
  const { scheduledTracks } = useScheduledTracks(pathwayId)
  const {
    onSelectActivity,
    selectedActivity,
    setSidePanelOpen,
    loading,
    pathwayStatusExplanation,
  } = usePathwayContextProvider()

  const {
    loading: activitiesLoading,
    newActivity,
    activityCompleted,
    activityUpdated,
    activityExpired,
    refetchActivities,
    pagination,
  } = useCareflowActivities(pathwayId)

  const [prompt, setPrompt] = useState('')

  const [activityFilters, setActivityFilters] = useState<ActivitiesListFilters>(
    {
      activity_type: [],
      activity_status: [],
      stakeholders: [],
      action: [],
      hide_system_activities: true,
      date_range: undefined,
    },
  )

  const { loading: chatLoading, answer } = useOrchestrationFactsPrompt(
    pathwayId,
    prompt,
  )

  /**
   * This is pretty hacky, I could not get apollo cache to update automatically
   * so I had to manually add new activities as they get generated, updated, completed or expired
   */
  useEffect(() => {
    if (!isNil(newActivity)) {
      if (
        activityFilters.hide_system_activities &&
        systemActivities.includes(newActivity.object.type)
      ) {
        return
      }
      // we don't have a component for scheduled activities so we have to skip them
      if (newActivity.action === ActivityAction.Scheduled) {
        return
      }
      setAllActivities(prev => [newActivity, ...prev])
    }
  }, [newActivity])

  useEffect(() => {
    setAllActivities(prev => {
      if (!isNil(activityCompleted))
        return updateActivityInList(activityCompleted, prev)
      if (!isNil(activityExpired))
        return updateActivityInList(activityExpired, prev)
      if (!isNil(activityUpdated))
        return updateActivityInList(activityUpdated, prev)
      return prev
    })
  }, [activityCompleted, activityExpired, activityUpdated])

  const handleFilterChange = (
    filterKey: keyof ActivitiesListFilters,
    value: SelectValue | boolean,
  ): void => {
    if (typeof value === 'boolean') {
      setActivityFilters({
        ...activityFilters,
        [filterKey]: value,
      })
    } else if (Array.isArray(value)) {
      const valuesArray = value.map(v => v.value)
      setActivityFilters({
        ...activityFilters,
        [filterKey]: valuesArray,
      })
    }
  }

  const handleDateRangeSelect = (from: string, to: string): void => {
    if (
      from !== activityFilters.date_range?.from ||
      to !== activityFilters.date_range?.to
    ) {
      setActivityFilters(prev => ({
        ...prev,
        date_range: { from, to },
      }))
    }
  }

  const handleActivitySelect = (activity: Activity): void => {
    setSidePanelOpen(true)
    onSelectActivity(activity)
  }

  const fetchActivities = async ({
    offset,
  }: {
    offset: number
  }): Promise<Array<Activity>> => {
    try {

      const date_range = activityFilters.date_range ? {
        from: startOfDay(parseISO(activityFilters.date_range.from)),
        to: endOfDay(parseISO(activityFilters.date_range.to)),
      } : undefined

      const filters = {
        ...activityFilters,
        date_range,
      }

      const res = await refetchActivities({
        filters,
        pagination: {
          count: PAGE_SIZE,
          offset,
        },
      })
      return res.data.careflowActivities.activities
    } catch (error) {
      console.error('Error fetching activities:', error)
      return []
    }
  }

  const fetchMoreActivities = async (): Promise<void> => {
    const newActivities = await fetchActivities({
      offset: index * PAGE_SIZE,
    })

    if (newActivities.length > 0) {
      setAllActivities(prev => [...prev, ...newActivities])
      setIndex(prev => prev + 1)
    }
  }

  useEffect(() => {
    const resetActivities = async (): Promise<void> => {
      setAllActivities([])
      setIndex(0)
      const activities = await fetchActivities({ offset: 0 })
      if (activities.length > 0) {
        setAllActivities(activities)
        setIndex(1)
      }
    }

    void resetActivities()
  }, [activityFilters])

  const scheduledElements = [
    ...(scheduledSteps ?? []),
    ...(scheduledTracks ?? []),
  ]

  const moreInformation = config.get<
    DynamicConfigTypes[DynamicConfigName.AUDIT_LOGS_AVA]['more_information']
  >('more_information', {
    message: 'Important disclaimer about Personal Health Information (PHI)',
    link: 'https://developers.awellhealth.com/faq#data-privacy',
  })

  if (loading) {
    return <PathwayActivityFeedSkeleton />
  }

  return (
    <>
      {showChat && (
        <Chat
          open={false}
          state={
            chatLoading
              ? 'streaming-answer'
              : isEmpty(answer)
                ? 'indeterminate'
                : 'done'
          }
          answer={answer}
          onSubmit={setPrompt}
          buttonLabel={'Ask Ava'}
          moreInformation={moreInformation as { message: string; link: string }}
        />
      )}
      <ActivityFeed>
        <ActivityFeed.Feed>
          <CareflowActivityFilters
            key={`careflowActivityFilters-${allActivities.length}`}
            pathwayDefinitionId={pathwayDefinitionId}
            pathwayId={pathwayId}
            activityFilters={activityFilters}
            handleFilterChange={handleFilterChange}
            handleDateRangeSelect={handleDateRangeSelect}
            loading={activitiesLoading}
          />
          <Feed
            activities={allActivities}
            selectedActivityId={selectedActivity?.id}
            onSelectActivity={handleActivitySelect}
            baselineDataPoints={baselineDataPoints}
            scheduledElements={scheduledElements}
            pathwayStatusExplanation={pathwayStatusExplanation}
            onLoadMore={fetchMoreActivities}
            hasMore={(pagination?.total_count ?? 0) > allActivities.length}
            loading={loading || activitiesLoading}
          />
        </ActivityFeed.Feed>
      </ActivityFeed>
    </>
  )
}

PathwayActivityFeed.displayName = 'PathwayActivityFeed'
