import { setupAnalytics } from '@awell/libs-web/analytics'
import { AuthenticationGuard } from '@awell/libs-web/authentication'
import { OrganizationSwitcher } from '@awell/libs-web/OrganizationSwitcher'
import { environment } from '@awell/libs-web/environment'
import { AppTheme } from '@awell/ui-kit/components/AppTheme'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { BreadcrumbsProvider } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { AnalyticsProvider } from 'use-analytics'
import { AppLayout } from '@awell/ui-kit/layouts/AppLayout'
import { navigate, Router } from '@reach/router'
import { StytchB2BProvider } from '@stytch/react/b2b'
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b'
import React, { type FC } from 'react'
import { I18nextProvider } from 'react-i18next'
import { GraphqlWrapper } from './GraphqlWrapper'
import { AppHeader } from './components/AppHeader'
import { FeatureGateProvider } from './features'
import i18n from './i18n'
import { Routes } from './routes'
import './styles/tailwind.css'
import { NotificationsProvider } from '@awell/libs-web/src/hooks/useNotifications'

const stytch = new StytchB2BUIClient(environment.auth.stytch_public_token, {
  cookieOptions: {
    opaqueTokenCookieName: `stytch_session`,
    domain: environment.auth.auth_cookies_allowed_domain,
    availableToSubdomains: true,
  },
})
const App: FC = () => {
  const analytics = setupAnalytics('Care')
  return (
    <StytchB2BProvider stytch={stytch}>
      <AnalyticsProvider instance={analytics}>
        <I18nextProvider i18n={i18n}>
          <NotificationsProvider>
            <AuthenticationGuard loadingComponent={<Spinner />}>
              <AppTheme seed='care'>
                <GraphqlWrapper>
                  <FeatureGateProvider>
                    <OrganizationSwitcher
                      onSwitch={() => {
                        void navigate('/')
                      }}
                      environment={environment.environment}
                    >
                      <BreadcrumbsProvider>
                        <AppLayout>
                          <AppLayout.Header>
                            <Router>
                              <AppHeader path='/*' />
                            </Router>
                          </AppLayout.Header>
                          <AppLayout.Body>
                            <ScrollContainer>
                              <Routes />
                            </ScrollContainer>
                          </AppLayout.Body>
                        </AppLayout>
                      </BreadcrumbsProvider>
                    </OrganizationSwitcher>
                  </FeatureGateProvider>
                </GraphqlWrapper>
              </AppTheme>
            </AuthenticationGuard>
          </NotificationsProvider>
        </I18nextProvider>
      </AnalyticsProvider>
    </StytchB2BProvider>
  )
}

export default App
