import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../../Typography'
import { colors } from '../../../utils/style-guide'
import { useStyles } from './useStyles'

export const EmptyListDropArea = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.questionDropArea}>
      <Text color={colors.neutralLight100}>
        {t('form:builder.dropzone_label')}
      </Text>
    </div>
  )
}

EmptyListDropArea.displayName = 'EmptyListDropArea'
