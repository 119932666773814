import React, { type FC, useEffect } from 'react'
import { type RouteComponentProps, useNavigate } from '@reach/router'
import Retool from 'react-retool'
import { isNil } from 'lodash'
import { useStytchMember } from '@stytch/react/b2b'
import { Button, Icon, Spinner } from '@awell-health/design-system'
import { useRetoolConfig } from '../../hooks/useRetoolConfig'
import { useTrackCustify } from '@awell/libs-web/analytics'
import { CustifyEvents } from '@awell/libs-web/experiments'

import { useRetool } from '../../hooks/useRetool/useRetool'
import { useNotifications } from '../../hooks/useNotifications'

interface WorklistPageParams {
  appId: string
}

export const WorklistPage: FC<RouteComponentProps<WorklistPageParams>> = ({
  appId,
}) => {
  const { member } = useStytchMember()
  const { retoolConfig } = useRetoolConfig()
  const { trackCustify } = useTrackCustify()
  const navigate = useNavigate()
  const { notifyError } = useNotifications()

  // allow for passing version param to override the release version from stytch
  const pathname = new URL(window.location.href)
  const params = new URLSearchParams(pathname.search)
  const releaseVersionInQueryParams = params.get('version')

  const app = retoolConfig.worklists.find(app => app.id === appId)

  if (isNil(member)) {
    return <Spinner />
  }

  useEffect(() => {
    if (isNil(appId)) {
      void navigate(`/worklists`)
    } else {
      void trackCustify(CustifyEvents.ACCESS_WORKLIST, {
        appId,
      })
    }
  }, [])

  const handleBackClick = (): void => {
    void navigate(`/worklists`)
  }

  /**
   * Query params have priority over the release version set in Stytch.
   * This easily allows us to override the release version for testing purposes.
   */
  const releaseVersionToLoad = !isNil(releaseVersionInQueryParams)
    ? releaseVersionInQueryParams
    : app?.releaseVersion // Release version set in Stytch

  const request = {
    user: {
      firstName: member.name,
      lastName: '',
      email: member.email_address,
      id: member.member_id,
    },
    groupIds: retoolConfig.permission_groups,
    landingPageUuid: appId ?? '',
    releaseVersion: releaseVersionToLoad,
  }

  const { url, loading, error } = useRetool(request)

  if (loading) {
    return <Spinner />
  }

  if (!isNil(error)) {
    notifyError({
      message: 'Unable to fetch work list',
      error,
    })
    void navigate(`/worklists`)
  }

  /**
   * Create a generic Retool field to avoid passing down individual properties
   *
   * Current situation:
   * {
   *  trusted_metadata: {
   *    locations: [],
   *    primary_care_physicians: [],
   *    permissions: [
   *      "manage-settings",
   *    ],
   *  }
   * }
   *
   * To be situation:
   * {
   *  trusted_metadata: {
   *    retool: {
   *      locations: [],
   *      primary_care_physicians: [],
   *    },
   *    permissions: [
   *      "manage-settings",
   *    ],
   *  }
   * }
   */
  const retoolTrustedMetadata = member?.trusted_metadata?.retool ?? {}

  /**
   * Should be removed once migration to retool props is complete
   * Keeping it in here for now given time constraints and backwards compatibility
   */
  const locations = member?.trusted_metadata?.locations ?? []
  const primary_care_physicians =
    member?.trusted_metadata?.primary_care_physicians ?? []

  const data = {
    /**
     * Spread the retoolProps object first. Why?
     * As long as there is no retool object with the same properties
     * and the old config is not explicitly removed, the old config will take presedence (backward compatibility)
     */
    ...retoolTrustedMetadata,
    locations,
    primary_care_physicians,
    /**
     * Retool has a bug where the release version is not passed into the Retool app.
     * Attempting to access it via {{ retoolContext.pageTag }} returns undefined.
     *
     * Although the Retool app loads with the correct version (with the request above), the release version is
     * not made available within the app itself.
     *
     * Passing it again via the data prop allows us to display the version number in the app's footer.
     * This helps us easily identify which version a user is currently using.
     */
    releaseVersion: releaseVersionToLoad,
  }

  return (
    <>
      <div className='p-3'>
        <Button variant='secondary' shape='squareLg' onClick={handleBackClick}>
          <Icon icon='RiArrowLeftSLine' size={16} />
        </Button>
      </div>
      <div className='h-full'>
        <Retool url={url} data={data} />
      </div>
    </>
  )
}

WorklistPage.displayName = 'WorklistPage'
