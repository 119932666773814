import { isNil } from 'lodash'
import React, { useEffect, useState, type FC } from 'react'

import { useTranslation } from 'react-i18next'
import { GenericSelectionDialog } from '../GenericSelectionDialog'
import { type DynamicVariable } from '../RichText/DynamicVariablePlugin'

interface VariableSelectionDialogProps {
  variables: Array<DynamicVariable>
  onSelect: (variable: DynamicVariable) => void
  onCreateVariable: () => void | Promise<void>
}

export const VariableSelectionDialog: FC<VariableSelectionDialogProps> = ({
  variables,
  onSelect,
  onCreateVariable,
}) => {
  const { t } = useTranslation()
  const [searchedVariables, setSearchedVariables] = useState<
    Array<DynamicVariable>
  >([])

  useEffect(() => {
    setSearchedVariables(variables)
  }, [variables])

  const handleClick = (variable: DynamicVariable): void => {
    onSelect(variable)
  }

  const handleSearch = (query: string): void => {
    const filteredList = variables.filter(el => {
      const searchAttribute = el?.label
      const normalizedQuery = query.toLowerCase()
      if (isNil(searchAttribute)) {
        // untitled variable has label of null but user can't be aware of that so for convenience we allow searching
        // by what is presented to the user
        return t('untitled_variable').toLowerCase().includes(normalizedQuery)
      }
      return searchAttribute.toLowerCase().includes(normalizedQuery)
    })
    setSearchedVariables(filteredList)
  }

  return (
    <GenericSelectionDialog<DynamicVariable>
      items={searchedVariables}
      title={t('datapoints:dynamic_variable_selection_title')}
      onSelect={handleClick}
      onSearch={handleSearch}
      onCreateItem={onCreateVariable}
      createButtonTitle={t('datapoints:dynamic_variable_selection_create_new')}
      searchPlaceholder={t('datapoints:dynamic_variable_selection_search_placeholder')}
    />
  )
}

VariableSelectionDialog.displayName = 'VariableSelectionDialog'
