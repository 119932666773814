import { type PlatePluginComponent, type TElement } from '@udecode/plate'
import classnames from 'classnames'
import { defaultTo, isNil, noop } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { type RenderElementProps } from 'slate-react'
import { type DynamicVariable, ConfigurationStatusState } from './types'
import { truncateInside } from '../../../utils'
import { colors } from '../../../utils/style-guide'
import { Warning } from '../../Icons'
import { IconWrapper } from '../../IconWrapper'
import { Tooltip } from '../../Tooltip'
import { useDataVariablesContext } from '../DataVariablesContextProvider'
import { useStyles } from './useStyles'

interface DynamicVariableElementInterface {
  variableId: string
}

interface Props {
  attributes: RenderElementProps['attributes']
  element: TElement<DynamicVariableElementInterface>
  children: JSX.Element
}

const UNASSIGNED_VARIABLE_LABEL = 'variable'
const CENTER_OFFSET_OF_EDITOR = 130

export const DynamicVariableElement: PlatePluginComponent<Props> = ({
  attributes,
  element,
  children,
}: {
  attributes: RenderElementProps['attributes']
  element: TElement<DynamicVariableElementInterface>
  children: JSX.Element
}) => {
  const dynamicVariableElementRef = useRef<HTMLElement>(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    loading,
    variables,
    selectedVariableId,
    onVariableClick = noop,
  } = useDataVariablesContext()
  const variable = defaultTo<DynamicVariable, DynamicVariable>(
    variables.find(v => v.id === element.variableId),
    {
      id: 'not-found',
      status: {
        status: ConfigurationStatusState.Invalid,
        errors: [],
      },
      transformations: [],
    },
  )
  const selected = selectedVariableId === variable?.id

  const {
    status: { status, errors },
    label,
  } = variable
  const isVariableInvalid = status === ConfigurationStatusState.Invalid
  const isActive = selected && !isVariableInvalid

  const errorMessages: Array<string> = []
  if (errors.includes('Missing label')) {
    errorMessages.push(t('datapoints:dynamic_variable_label_missing_error'))
  }
  if (errors.includes('Missing data point definition')) {
    errorMessages.push(t('datapoints:dynamic_variable_data_point_missing_error'))
  }
  if (errors.includes('Missing data point property')) {
    errorMessages.push(t('datapoints:dynamic_variable_data_point_property_missing_error'))
  }

  const scrollTo = (offset: number): void => {
    // scrolls to the offset inside the editor div
    const grandParentElement =
      dynamicVariableElementRef.current?.parentElement?.parentElement
        ?.parentElement
    if (grandParentElement) {
      grandParentElement.scrollTop = offset
    }
  }

  useEffect(() => {
    if (dynamicVariableElementRef.current) {
      const sessionStorageScrollPosition = sessionStorage.getItem(
        'currentEditorScroll',
      )
      const currentScroll = !isNil(sessionStorageScrollPosition)
        ? parseInt(sessionStorageScrollPosition, 10)
        : undefined
      // when page is refreshed
      if (isNil(currentScroll) && selected) {
        scrollTo(
          dynamicVariableElementRef.current.offsetTop - CENTER_OFFSET_OF_EDITOR,
        )
        return
      }
      // when we open the variable configuration panel
      if (isActive && !isVariableInvalid && !isNil(currentScroll)) {
        scrollTo(currentScroll - CENTER_OFFSET_OF_EDITOR)
        return
      }
      // when we create a new variable
      if (isVariableInvalid && selected) {
        // we don't save a value in sessionStorage when we create a variable, so we scroll directly
        scrollTo(
          dynamicVariableElementRef?.current?.offsetTop -
            CENTER_OFFSET_OF_EDITOR,
        )
      }
    }
  }, [dynamicVariableElementRef])

  useEffect(() => {
    // when we close the variable configuration panel
    if (isNil(selectedVariableId)) {
      sessionStorage.removeItem('currentEditorScroll')
    }
  }, [selectedVariableId])

  const handleVariableClick = (): void => {
    if (dynamicVariableElementRef.current) {
      sessionStorage.setItem(
        'currentEditorScroll',
        `${dynamicVariableElementRef.current.offsetTop}`,
      )
    }
    onVariableClick(variable.id)
  }
  const nonExistentVariableId =
    loading === false && !variables.find(v => v.id === element.variableId)

  return (
    <div {...attributes} className={classes.wrapper}>
      <Tooltip
        info={
          nonExistentVariableId
            ? t('datapoints:variable_does_not_exist_tooltip')
            : errorMessages.join(', ')
        }
        arrow
      >
        <div>
          {!nonExistentVariableId && (
            <span
              onClick={handleVariableClick}
              role='presentation'
              className={classnames(classes.container, {
                [classes.rest]: !isVariableInvalid && !isActive,
                [classes.active]: isActive,
                [classes.error]: isVariableInvalid,
                [classes.highlight]: selected,
              })}
              ref={dynamicVariableElementRef}
            >
              {isVariableInvalid && (
                <span style={{ marginRight: 2 }}>
                  <IconWrapper color={colors.signalError100} size='xs'>
                    <Warning />
                  </IconWrapper>
                </span>
              )}
              {/* eslint-disable-next-line no-nested-ternary */}
              {!isNil(label)
                ? truncateInside()(label)
                : t(UNASSIGNED_VARIABLE_LABEL)}
              {children}
            </span>
          )}

          {nonExistentVariableId && (
            <span
              role='presentation'
              className={classnames(classes.container, classes.notExistentVar)}
              ref={dynamicVariableElementRef}
            >
              {isVariableInvalid && (
                <span style={{ marginRight: 2 }}>
                  <IconWrapper color={colors.signalError100} size='xs'>
                    <Warning />
                  </IconWrapper>
                </span>
              )}
              {/* eslint-disable-next-line no-nested-ternary */}
              {t('datapoints:variable_does_not_exist')}
              {children}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  )
}
