export enum FeatureGateName {
  AUDIT_LOGS_AVA = 'audit_logs_ava',
  CHANGE_MANAGEMENT_DIFF = 'change_management_diff',
  CODE_DIFF = 'code_diff',
  ONE_MEDICAL_TMS_FORM_MAPPER = 'one_medical_treat-me-now_form_input_mapper',
  CUSTOM_THEME = 'custom_theme',
  CHATBOT = 'chatbot',
  ALLOW_MULTI_CHOICE_QUESTION_VALIDATION = 'allow_choice_limit_in_multi-select_questions',
  AUTOMATIC_EXTENSION_DATA_VARIABLES = 'automatic-extension-data-variables',
  WORKING_HOURS = 'working_hours',
  FHIR_INTEGRATION = 'fhir_integration',
  NEW_DATA_SEARCH_RULE_EDITOR = 'new_datasearch_select_rule_editor',
  NEW_DATA_POINT_SELECTOR = 'new_data_point_selector',
  TENANT_WEBHOOKS = 'tenant_webhooks',
  SANKEY = 'sankey',
  GLOBAL_CONSTANTS = 'global_constants',
  BACKOFFICE = 'backoffice',
  NEW_FORM_BUILDER = 'new_form_builder',
  ACTIVITY_DUE_DATE = 'activity_due_date',
  RESTORE_CAREFLOW = 'version_control',
  SHELLY_IN_FORMS = 'shelly_in_forms',
  DATA_INGESTION_MONITORING = 'data_ingestion_monitoring',
  HYPERLINK_DYNAMIC_VARIABLES = 'hyperlink_in_dynamic_variables',
  DYNAMIC_WORKLISTS = 'dynamic-worklists'
}
